import React from "react";
import PageTitle from "../../Shared/PageTitle/PageTitle";
import ReportForm from "./ReportForm";
import FlashMessageList from "../../FlashMessage";

import ReportList from "./ReportList";
import ITableState from "../../../interfaces/ITableState";
import { TabLinks } from "../../Shared";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { IReportFormValues } from "../../../interfaces/IReport";
import * as Actions from "../../../actions/OEMReportActions";
import ManualBudgetList from "../../Client/Budgets/Manual/ManualBudgetList";

const Index: React.FunctionComponent<any> = (props) => {
    return (
        <div className="mx-4 text-base mb-4">
            <PageTitle title="OEM Reports" />
            <FlashMessageList timeout={10000} />
            <div className="mx-2">
                <TabLinks
                    tabs={[
                        {
                            label: "Re-Run OEM Reports",
                            path: "/admin/oem-reports",
                            show: true
                        },
                        {
                            label: "Facebook - FDAF",
                            path: "/admin/oem-reports/social",
                            show: true
                        },
                        {
                            label: "Cars Premium Display - FDAF",
                            path: "/admin/oem-reports/cars-premium-display",
                            show: true
                        },
                        {
                            label: "Programmatic Display - FDAF",
                            path: "/admin/oem-reports/dsp",
                            show: true
                        }
                    ]}
                />
                <div className="bg-white shadow rounded p-5">
                    <Routes>
                        <Route element={<OEMReports {...props} />} path={"/"} />
                        <Route element={<ManualBudgetList />} path={"dsp"} />
                        <Route element={<ManualBudgetList />} path={"social"} />
                        <Route element={<ManualBudgetList />} path={"cars-premium-display"} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

const OEMReports = ({ currentUser, reportHandler, isSubmitting }: any) => {
    if (!currentUser?.id) return <></>; //this is necesary because currentUser loads asynchronously, this condition forces rerender when currentUser loads. Without this, the table renders empty.
    const userHasPermission = currentUser?.roles?.map((role: any) => role.name).includes("report_manager");

    return (
        <>
            {userHasPermission && (
                <>
                    <div>
                        Please fill in the OEM and the report you would like to run as well as the date you would like
                        to re-run for it. It can take a couple hours for the reporting to complete based on the OEM and
                        number of days.
                    </div>
                    <div className="bg-white rounded shadow pb-4 px-4 my-4">
                        <ReportForm reportHandler={reportHandler} isSubmitting={isSubmitting} />
                    </div>
                </>
            )}
            <div className="bg-white rounded">
                <ReportList />
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        reports: state.OEMReports.entities,
        isSubmitting: state.OEMReports.isSubmitting,
        currentUser: state.currentUser,
        loading: state.OEMReports.loading
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        createReportJob: (reportFormValues: IReportFormValues) => {
            dispatch(Actions.createReportJob(reportFormValues));
        },
        reportHandler: (reportFormValues: IReportFormValues) => {
            dispatch(Actions.createReportJob(reportFormValues));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
