import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { callApi, doDeleteRequest, getPagedResults, multipartCallApi } from "../middleware/api";
import schemas from "../middleware/schemas/negativeKeywords";
import * as Actions from "../actions/shared/itemCollectionActions";
import Constants from "../constants/shared/ItemCollectionConstants";
import NegativeKeywordConstants from "../constants/NegativeKeywords";
import { addFlashMessage } from "../actions/flashMessageActions";

export function* fetchNegativeKeywordLists(): Generator<any, any, any> {
    const url = `/negative-keyword-colls?limit=1000`;
    try {
        const response = yield call(callApi, url, schemas.NEGATIVE_KEYWORD_COLLECTIONS);
        yield put(
            Actions.fetchCollectionListSuccess(
                NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER,
                response.entities.negativeKeywordCollections
            )
        );
    } catch (error) {
        const errorMessage = error.errors ? error.errors.text["0"] : error.exceptionMessage;
        yield put(
            addFlashMessage({
                type: "danger",
                text: errorMessage
            })
        );
    }
}

export function* fetchNegativeKeywordCollection({ payload }: any): Generator<any, any, any> {
    const { collectionId, page, searchTerm } = payload;
    let url: string;

    if (searchTerm === "") {
        url = `/negative-keywords?filter[collection_id]=${collectionId}&page=${page}&limit=1000`;
    } else {
        url = `/negative-keywords?filter[collection_id]=${collectionId}&search[text]=like,*${searchTerm}*&page=${page}&limit=1000`;
    }

    try {
        const response = yield call(getPagedResults, url, schemas.NEGATIVE_KEYWORD_COLLECTION, 1000);
        yield put(
            Actions.fetchItemCollectionSuccess(
                NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER,
                response,
                collectionId,
                searchTerm
            )
        );
    } catch (error) {
        const errorMessage = error.errors ? error.errors.text["0"] : error.exceptionMessage;
        yield put(
            Actions.fetchItemCollectionFailure(
                NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER,
                errorMessage,
                searchTerm
            )
        );
        yield put(
            addFlashMessage({
                type: "danger",
                text: errorMessage
            })
        );
    }
}

export function* removeNegativeKeywordFromCollection({ payload }: any): Generator {
    const url = `/negative-keywords/${payload}`;
    try {
        yield call(doDeleteRequest, url);
        yield put(Actions.removeItemFromCollectionSuccess(NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER, payload));
    } catch (error) {
        const errorMessage = error.errors ? error.errors.text["0"] : error.exceptionMessage;
        yield put(
            Actions.removeItemFromCollectionFailure(
                NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER,
                payload,
                errorMessage
            )
        );
        yield put(
            addFlashMessage({
                type: "danger",
                text: errorMessage
            })
        );
    }
}

export function* addNegativeKeywordToCollection({ payload }: any): Generator<any, any, any> {
    const url = `/negative-keywords`;
    try {
        const response = yield call(callApi, url, schemas.NEGATIVE_KEYWORD, "POST", {
            text: payload.itemName,
            collection_id: payload.collectionId
        });
        yield put(
            Actions.addItemToCollectionSuccess(
                NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER,
                response.entities.items,
                response.result,
                payload.dispatchedAt
            )
        );
    } catch (error) {
        const errorMessage = error.errors ? error.errors.text["0"] : error.exceptionMessage;
        yield put(
            addFlashMessage({
                type: "danger",
                text: errorMessage
            })
        );
        yield put(
            Actions.addItemToCollectionFailure(
                NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER,
                payload.dispatchedAt,
                errorMessage
            )
        );
    }
}

export function* uploadNegativeKeywordCSV({ payload: { id, fileInput } }: any) {
    const url = `/negative-keyword-colls/${id}/keywords`;
    const formData = new FormData();
    formData.append("keywords", fileInput.files[0]);
    try {
        yield multipartCallApi(url, formData, "POST");
        yield put(
            addFlashMessage({
                type: "success",
                text: `Keywords upload successful.  We'll notify you when they have finished.`
            })
        );
        yield put(Actions.uploadCSVSuccess(NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER));
    } catch (error) {
        yield put(
            addFlashMessage({
                type: "danger",
                text: `Failed to upload: "${fileInput.files[0].name}".`
            })
        );
        yield put(Actions.uploadCSVFailure(NegativeKeywordConstants.ACTION_TYPE_IDENTIFIER));
    }
}

function* negativeKeywordSagas() {
    yield takeLatest(`${Constants.FETCH_COLLECTION_LIST}_NEGATIVE_KEYWORDS`, fetchNegativeKeywordLists);
    yield takeLatest(`${Constants.FETCH_ITEM_COLLECTION}_NEGATIVE_KEYWORDS`, fetchNegativeKeywordCollection);
    yield takeEvery(`${Constants.REMOVE_ITEM_FROM_COLLECTION}_NEGATIVE_KEYWORDS`, removeNegativeKeywordFromCollection);
    yield takeEvery(`${Constants.ADD_ITEM_TO_COLLECTION}_NEGATIVE_KEYWORDS`, addNegativeKeywordToCollection);
    yield takeEvery(`${Constants.UPLOAD_CSV}_NEGATIVE_KEYWORDS`, uploadNegativeKeywordCSV);
}

export default negativeKeywordSagas;
